import styled from "styled-components";
import { CSSVars } from "../../styles/styles.config";

export const StyledParallaxEditor = styled.section`
  &.parallax-container {
    position: relative;
    
	
    transition-timing-function: ease !important;
	
    width: 1100px;
    margin: auto;
    object-fit: cover;
	
    *:not(.editor-container) {
      position: absolute;
      z-index: 2;
      top: 0;
      right: 0;
    }

    .editor-container {
      width: 100%;
      transition: all 0.4s;

      transform: perspective(1200px) rotateX(var(--rotate-x, 0))
        rotateY(var(--rotate-y, 0));
    }

    // elements transition
    .editor-dashboard,
    .layouts-and-skins,
    .plugin {
      transition: all 0.5s;
    }

    .layouts-and-skins {
      width: 100%;
      height: 100%;

      transform: perspective(1400px) rotateX(var(--rotate-x, 0))
        rotateY(var(--rotate-y, 0))
        translate(var(--translate-x, 0), var(--translate-y, 0));

      .layouts {
        top: 18.5%;
        left: 80.5%;
        width: 19.5%;
      }

      .skins {
        top: 72%;
        left: 74.5%;
        width: 22.6%;
      }
    }

    .editor-dashboard {
      top: 22%;
      left: 10%;
      width: 19.5%;

      transform: perspective(1200px) rotateX(var(--rotate-x, 0))
        rotateY(var(--rotate-y, 0))
        translate(var(--translate-x, 0), var(--translate-y, 0));
    }

    .plugin {
      width: 43%;
      top: 16%;
      left: 30.5%;

      transform: perspective(1200px) rotateX(var(--rotate-plugin-x, 0))
        rotateY(var(--rotate-y, 0))
        translate(var(--translate-plugin-x, 0), var(--translate-plugin-y, 0));
    }

    @media screen and (max-width: ${CSSVars.breakpointMD}) {
      width: 145%;
      max-width: none;
      margin-bottom: 90px;

      .editor-dashboard {
        animation: editor-dashboard-float 5.5s linear reverse infinite;
      }

      .plugin {
        animation: plugin-float 4s linear infinite;
      }
    }
  }

  @keyframes plugin-float {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-5px);
    }
    100% {
      transform: translateY(0px);
    }
  }
  @keyframes editor-dashboard-float {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-8px);
    }
    100% {
      transform: translateY(0px);
    }
  }
`;
