import(/* webpackMode: "eager", webpackExports: ["Features"] */ "/vercel/path0/src/components/features/features.comp.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FooterBanner"] */ "/vercel/path0/src/components/footerBanner/footerBanner.comp.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Header"] */ "/vercel/path0/src/components/header/header.comp.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Hero"] */ "/vercel/path0/src/components/hero/hero.comp.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MadeWith"] */ "/vercel/path0/src/components/madeWidth/madeWith.comp.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NumbersAndReviews"] */ "/vercel/path0/src/components/numbers-and-reviews/numbers-and-reviews.comp.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PlatformSupport"] */ "/vercel/path0/src/components/platformSupport/platformSupport.comp.tsx");
