import styled from 'styled-components';
import { CSSVars } from '../../styles/styles.config';

export const StyledDiv = styled.div`
	color: inherit;
	background: none !important;
	width: fit-content;
	padding: 0 !important;
	display: flex;
	align-items: center;
	gap: 15px;
	flex-wrap: wrap;
	justify-content: center;
	margin: 0 auto;

	
	ul[aria-label='avatar group'] span {
		box-shadow: 0 0 0 3px ${CSSVars.black};
		background: none;
	}

	.rating {
		> svg path {
			fill: #f5f5f5;
		}

		> p {
			color: #f5f5f5;
		}
	}

	@media screen and (max-width: ${CSSVars.breakpointLG}) {
		margin-bottom: 50px;
	}

	.rating {
		display: flex;
		flex-direction: column;
		gap: 5px;
		/* max-width: 170px; */

		p {
			font-size: ${CSSVars.fontSizeXS};
			font-weight: 300;
			text-align: left;
		}
	}

	@media screen and (min-width: ${CSSVars.breakpointSM}) {
		justify-content: center;
	}
`;
