"use client";

import React, { useState, useEffect, useRef } from "react";
import { StyledDiv } from "./madeWith.styles";
import { useParallax } from "react-scroll-parallax";

export const MadeWith = () => {
  const columns = { 1: 5, 2: 5, 3: 7, 4: 6, 5: 5, 6: 5 };
  let imageCounter = 1;

  const [scale, setScale] = useState(1);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [containerTopRect, setContainerTopRect] = useState<number>();

  const animationFrameId = useRef<number | null>(null);
  const previousScale = useRef(scale);

  const { ref } = useParallax({
    translateY: ["0px", "400px"],
  });

  const lerp = (start: number, end: number, t: number) => {
    return start * (1 - t) + end * t;
  };

  function updateContainerPosition() {
    if (containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      const animationStartOffset = 600;
      const top = rect.top + window.scrollY - animationStartOffset;
      setContainerTopRect(top);
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", updateContainerPosition);
    window.addEventListener("resize", updateContainerPosition);
    updateContainerPosition();

    return () => {
      window.removeEventListener("scroll", updateContainerPosition);
      window.removeEventListener("resize", updateContainerPosition);
    };
  }, []);

  useEffect(() => {
    const smoothScrollHandler = () => {
      if (!containerTopRect) return;

      const scrollPosition = window.scrollY;
      const animationStart = containerTopRect;
      const animationEnd = containerTopRect + 1500;
      // const targetScale = scrollPosition >= animationStart && scrollPosition <= animationEnd
      //     ? 1.1 - ((scrollPosition - animationStart) / (animationEnd - animationStart)) * 0.35
      //     : 1.1;
      const targetScale =
        1.1 -
        ((scrollPosition - animationStart) / (animationEnd - animationStart)) *
          0.35;

      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current);
      }

      animationFrameId.current = requestAnimationFrame(() => {
        const smoothScale = lerp(previousScale.current, targetScale, 0.2);
        previousScale.current = smoothScale;
        setScale(smoothScale);

        if (Math.abs(targetScale - smoothScale) > 0.001) {
          requestAnimationFrame(smoothScrollHandler);
        }
      });
    };

    window.addEventListener("scroll", smoothScrollHandler);
    smoothScrollHandler();

    return () => {
      window.removeEventListener("scroll", smoothScrollHandler);
      if (animationFrameId.current)
        cancelAnimationFrame(animationFrameId.current);
    };
  }, [containerTopRect]);

  return (
    <>
      <StyledDiv>
        <h2 ref={ref as any}>Made with Common Ninja</h2>
        <div
          ref={containerRef}
          className="images-container"
          style={{
            transform: `scale(${scale}) translateY(0px)`,
          }}
        >
          {Object.values(columns).map((totalImages, idx) => (
            <div key={idx} className="column">
              {Array.from({ length: totalImages }).map((_, imgIdx) => {
                const imageUrl = `/assets/made-with/${imageCounter}.webp`;
                const link = imageLinks[imageCounter - 1];
                imageCounter++;
                const widgetName = link.split("/").pop()?.replace("-", " ");

                return (
                  <a
                    key={`column-${idx}-${imgIdx}`}
                    className="image-item"
                    href={link}
                    target="_blank"
                  >
                    <span className="overlay"></span>
                    <img
                      src={imageUrl}
                      alt={`${widgetName} widget made with Common Ninja`}
                      loading="lazy"
                    />
                  </a>
                );
              })}
            </div>
          ))}
        </div>
      </StyledDiv>
    </>
  );
};

const imageLinks = [
  "https://www.commoninja.com/widgets/social-proof",
  "https://www.commoninja.com/widgets/poll",
  "https://www.commoninja.com/widgets/pricing-tables",
  "https://www.commoninja.com/widgets/business-hours",
  "https://www.commoninja.com/widgets/form-builder",
  "https://www.commoninja.com/widgets/all-in-one-reviews",
  "https://www.commoninja.com/widgets/audio-player",
  "https://www.commoninja.com/widgets/code-snippets",
  "https://www.commoninja.com/widgets/weather",
  "https://www.commoninja.com/widgets/charts",
  "https://www.commoninja.com/widgets/corner-coupon-popup",
  "https://www.commoninja.com/widgets/booking",
  "https://www.commoninja.com/widgets/maps",
  "https://www.commoninja.com/widgets/leaderboard",
  "https://www.commoninja.com/widgets/pricing-tables",
  "https://www.commoninja.com/widgets/audio-player",
  "https://www.commoninja.com/widgets/code-snippets",
  "https://www.commoninja.com/widgets/testimonials",
  "https://www.commoninja.com/widgets/charts",
  "https://www.commoninja.com/widgets/video-slider",
  "https://www.commoninja.com/widgets/reviews-trust-box",
  "https://www.commoninja.com/widgets/image-hotspot",
  "https://www.commoninja.com/widgets/business-hours",
  "https://www.commoninja.com/widgets/poll",
  "https://www.commoninja.com/widgets/interactive-video",
  "https://www.commoninja.com/widgets/all-in-one-chat-button",
  "https://www.commoninja.com/widgets/faq",
  "https://www.commoninja.com/widgets/code-snippets",
  `https://www.commoninja.com/widgets/social-proof`,
  "https://www.commoninja.com/widgets/before-after",
  "https://www.commoninja.com/widgets/faq",
  `https://www.commoninja.com/widgets/coupon-popup`,
  `https://www.commoninja.com/widgets/petition-form`,
];
