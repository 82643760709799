import { CSSVars } from "@/styles/styles.config";
import styled from "styled-components";

export const StyledFooterBanner = styled.div`
  background: url(/assets/homepage-footer-background.webp),
    linear-gradient(0deg, black, white);
  background-size: 120% 1550px;
  background-repeat: no-repeat;
  background-position: center 0%;

  padding: 30% 0 0;
  margin-top: -16%;

  color: var(--white, #fff);
  text-align: center;

  --hover-transition-duration: 0.3s;

  .card-container {
    width: 800px;
    max-width: 90%;
    margin: auto;
    border-radius: 8px;
    border: 1px solid var(--white, #fff);
    padding: 50px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    position: relative;

    /* Light Shadow */
    box-shadow: 0px 0px 20px 0px rgba(255, 255, 255, 0.1);

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(
        180deg,
        rgba(76, 79, 255, 0) 19.56%,
        rgba(76, 79, 255, 0) 37.46%,
        #fff 113.78%
      );
      opacity: 0;
      transition: opacity var(--hover-transition-duration) linear;
      border-radius: inherit;
    }

    &:hover {
      &::before {
        opacity: 0.8;
      }

      .footer-banner-button {
        background: White;
        color: black;
        z-index: 1;
      }
    }

    @media screen and (max-width: ${CSSVars.breakpointMD}) {
      padding: 30px 35px;
    }

    .small-title {
      font-size: ${CSSVars.fontSizeLG};
      font-weight: 400;
      letter-spacing: 5.4px;
      font-weight: 300;
      text-transform: uppercase;
    }

    .large-title {
      margin-top: 20px;

      &::before {
        content: "To Stand Out";
        font-size: ${CSSVars.fontSize6XL};
        line-height: 185px;
        text-wrap: nowrap;

        background: linear-gradient(277deg, #fff 48.61%, #808aad 109.97%);
        background-clip: text;
        color: transparent;
      }

      @media screen and (max-width: ${CSSVars.breakpointXL}) {
        &::before {
          font-size: 150px;
        }
      }

      @media screen and (max-width: ${CSSVars.breakpointLG}) {
        &::before {
          font-size: 120px;
        }
      }

      @media screen and (max-width: ${CSSVars.breakpointMD}) {
        margin-top: 0px;
        &::before {
          font-size: ${CSSVars.fontSize4XL};
          content: "Stand Out";
          letter-spacing: -1.5px;
          line-height: normal;
        }
      }

      @media screen and (max-width: 369px) {
        &::before {
          font-size: ${CSSVars.fontSize3XL};
        }
      }
    }

    .footer-banner-button {
      color: var(--White, #fff);
      font-size: ${CSSVars.fontSize2XL};
      line-height: 40px;
      font-weight: 300;

      padding: 12px 30px;
      border-radius: 8px;
      border: 1px solid var(--White, #fff);
      margin-top: 30px;

      transition: color var(--hover-transition-duration) linear,
        background-color var(--hover-transition-duration) linear;

      @media screen and (max-width: ${CSSVars.breakpointMD}) {
        margin-top: 0px;
        font-size: ${CSSVars.fontSizeLG};
        padding: 8px 25px;
      }
    }
  }

  @media screen and (max-width: ${CSSVars.breakpoint2XL}) {
    background-size: 120% 1400px;
  }
  @media screen and (max-width: ${CSSVars.breakpointXL}) {
    background-size: 120% 1300px;
  }

  @media screen and (max-width: ${CSSVars.breakpointLG}) {
    margin-top: -25%;
    background-size: 120% 1150px;
  }

  @media screen and (max-width: ${CSSVars.breakpointMD}) {
    margin-top: -45%;
    padding: 60% 0 0;
    background-size: 120% 850px;
  }

  @media screen and (max-width: ${CSSVars.breakpointSM}) {
    background-size: 120% 750px;
  }
`;
