import styled from 'styled-components';
import { CSSVars } from '../../styles/styles.config';

export const StyledHero = styled.section`
	overflow: hidden;
	padding: 50px 00px 50px;
	transform-style: preserve-3d;
	background-color: #010101;

	--icons-hover-base-duration: 5s;

	.hero-content-container {
		margin-top: 100px;

		.header-background-overlay {
			position: absolute;
			height: 750px;
			width: 100%;

			z-index: -1;
			top: 0;
			left: 0;

			display: flex;
			gap: 20px;

			* {
				box-sizing: content-box;
			}

			> img {
				position: absolute;
				right: 0;
			}

			.shadow {
				border: 1px rgba(53, 53, 53, 0.3) solid;
				border-radius: 5px;

				filter: blur(2.6px);

				&.left {
					transform: rotateX(30deg) rotateY(-20deg);
					position: absolute;
				}

				&.right {
					transform: rotateX(-32deg) rotateY(-20deg);
					position: absolute;
				}
			}

			//left side
			.shadow-left-L {
				background: linear-gradient(
					244.07deg,
					rgba(46, 46, 46, 0.8) -7.79%,
					rgba(0, 0, 0, 0.8) 70.79%
				);

				top: 45%;
				left: 5%;

				animation: left-shadow-float
					calc(var(--icons-hover-base-duration) * 0.625) ease-in-out infinite;
			}

			.shadow-left-S {
				background: linear-gradient(
					275.12deg,
					rgba(46, 46, 46, 0.4) 3.42%,
					rgba(0, 0, 0, 0.4) 112.81%
				);

				top: 60%;
				left: 29%;

				animation: left-shadow-float calc(var(--icons-hover-base-duration))
					ease-in-out reverse infinite;
			}

			.shadow-left-M {
				background: linear-gradient(
					275.12deg,
					rgba(46, 46, 46, 0.8) 3.42%,
					rgba(0, 0, 0, 0.8) 112.81%
				);

				&.a {
					top: 10%;
					left: 12.5%;

					animation: left-shadow-float
						calc(var(--icons-hover-base-duration) * 0.75) ease-in-out infinite;
				}
				&.b {
					top: 40%;
					left: 23%;
					animation: left-shadow-float
						calc(var(--icons-hover-base-duration) * 1.125) ease-in-out reverse
						infinite;
				}
			}

			.graph {
				top: 22%;
				left: 12%;
				animation: images-float calc(var(--icons-hover-base-duration) * 0.75)
					ease-in-out reverse infinite;
			}

			.watch {
				top: 7%;
				left: 28%;
				animation: images-float calc(var(--icons-hover-base-duration))
					ease-in-out infinite;
			}

			.star {
				top: 60%;
				left: 17%;
				animation: images-float calc(var(--icons-hover-base-duration) * 1.25)
					ease-in-out infinite;
			}

			// right side
			.shadow-right-S {
				background: linear-gradient(282.95deg, #2e2e2e 10.72%, #000000 119.44%);

				right: 22%;
				top: 34%;

				animation: right-shadow-float
					calc(var(--icons-hover-base-duration) * 1.125) ease-in-out infinite;
			}
			.shadow-right-M {
				background: linear-gradient(
					259.65deg,
					rgba(46, 46, 46, 0.8) -20.54%,
					rgba(0, 0, 0, 0.8) 104.52%
				);

				right: 16%;
				top: 14%;

				animation: right-shadow-float
					calc(var(--icons-hover-base-duration) * 0.875) ease-in-out reverse
					infinite;
			}
			.shadow-right-L {
				background: linear-gradient(
					259.65deg,
					rgba(46, 46, 46, 0.8) -20.54%,
					rgba(0, 0, 0, 0.8) 104.52%
				);

				right: 6%;
				top: 40%;

				animation: right-shadow-float
					calc(var(--icons-hover-base-duration) * 1.25) ease-in-out reverse
					infinite;
			}

			.play {
				right: 24%;
				top: 11%;
				animation: images-float calc(var(--icons-hover-base-duration) * 0.875)
					ease-in-out reverse infinite;
			}

			.music {
				right: 13%;
				top: 25%;
				animation: images-float calc(var(--icons-hover-base-duration) * 1.125)
					ease-in-out infinite;

				@media screen and (max-width: ${CSSVars.breakpointMD}) {
					top: 37%;
				}
			}

			.chat {
				right: 15%;
				top: 47%;
				animation: images-float calc(var(--icons-hover-base-duration) * 1.125)
					ease-in-out infinite;
			}

			// layout on mobile
			@media screen and (max-width: ${CSSVars.breakpointLG}) {
				*:not(.graph, .play, .star, .shadow-right-S, .shadow-right-M, .music) {
					display: none;
				}

				.graph {
					width: 60px;
					height: auto;
					left: -30px;
					top: 30.5%;
				}

				.star {
					width: 80px;
					height: auto;
					top: 8%;
					left: 16%;
				}

				.play {
					width: 84px;
					height: auto;
					top: 8%;
					right: -15px;
				}

				.shadow-right-S {
					right: 12%;
					top: 10%;
					filter: blur(2px);
					opacity: 0.8;
				}
				.shadow-right-M {
					right: -14px;
					top: 28%;

					filter: blur(4.7px);
					opacity: 0.8;
				}
			}

			/* placing star next to title gradient on each screen side */
			@media screen and (max-width: ${CSSVars.breakpointMD}) {
				.star {
					left: 6%;
				}

				.music {
					right: 6%;
				}

				.play {
					right: -40px;
				}
			}
			@media screen and (max-width: ${CSSVars.breakpointSM}) {
				.star {
					left: 3%;
				}

				.shadow-right-S {
					right: 19%;
					top: 15%;
				}
			}
		}

		// title gradient box
		.gradient-container {
			width: min(80%, 500px);
			height: 300px;
			position: relative;
			overflow: visible;
			margin: auto;
			border-radius: 8px;
			border-image-slice: 1;
			background: linear-gradient(
				279.55deg,
				rgba(0, 0, 0, 0) 17.29%,
				rgba(76, 79, 255, 0.761737) 58.16%,
				rgba(255, 151, 156, 0.8) 90.54%
			);

			// container border
			&::after {
				content: '';
				position: absolute;
				inset: 0;
				border-radius: 8px;
				padding: 2px; /* border thickness */
				background: linear-gradient(188.68deg, #ff979c 12.61%, #4c4fff 100%);
				-webkit-mask: linear-gradient(#fff 0 0) content-box,
					linear-gradient(#fff 0 0);
				-webkit-mask-composite: xor;
				mask-composite: exclude;
			}

			.gradient-text {
				font-size: 95px;
				text-wrap: nowrap;
				color: #ffffff;
				font-weight: 300;
				user-select: none;
				--text-animation-duration: 20s;

				&.left-text {
					z-index: -1;
					position: absolute;
					left: -35%;
					top: 5%;
				}
				&.right-text {
					z-index: 1;
					position: absolute;
					left: 11%;
					bottom: 9%;
					background: linear-gradient(
						276.55deg,
						#ffffff 48.61%,
						#b4c5ff 109.97%
					);
					background-clip: text;
					color: transparent;
				}
			}

			&:not(.first-render) {
				.gradient-text {
					&.left-text,
					&.right-text {
						opacity: 0;
					}
				}
			}

			&.first-render {
				.gradient-text {
					&.left-text {
						animation: swipe-to-left 1s cubic-bezier(0.25, 1, 0.5, 1),
							move-to-left var(--text-animation-duration) ease 1s;
					}
					&.right-text {
						animation: swipe-to-right 1s cubic-bezier(0.25, 1, 0.5, 1),
							move-to-right var(--text-animation-duration) ease 1s;
					}
				}
			}

			&.animate {
				.gradient-text {
					&.left-text {
						opacity: 1;
						z-index: -1;
						animation: move-to-left var(--text-animation-duration) ease;
					}
					&.right-text {
						opacity: 1;
						animation: move-to-right var(--text-animation-duration) ease;
					}
				}
			}

			&.animate,
			&.first-render {
				.gradient-text {
					&.left-text,
					&.right-text {
						&.firefox-browser {
							animation: none;
							transition: opacity 1s;
						}
					}
				}
			}

			@media screen and (max-width: ${CSSVars.breakpointLG}) {
				height: 150px;
				width: min(80%, 350px);

				&::after {
					padding: 1px;
				}

				&:not(.first-render) {
					.gradient-text {
						&.left-text,
						&.right-text {
							opacity: 1;
						}
					}
				}

				&.first-render {
					.gradient-text {
						&.left-text,
						&.right-text {
							transform: translateX(-50%);
							animation: none;
						}
					}
				}

				.gradient-text {
					&.left-text,
					&.right-text {
						animation: none;
					}
				}

				&.animate {
					.gradient-text {
						&.left-text {
							animation: none;
						}
						&.right-text {
							animation: none;
						}
					}
				}

				.gradient-text {
					font-size: 50px;

					&.left-text,
					&.right-text {
						left: 50%;
						transform: translateX(-50%);
					}
					&.left-text {
						top: 5%;
					}
				}
			}
		}

		.title-description {
			color: white;

			width: min(80%, 600px);
			margin: auto;
			font-size: 16px;
			white-space: break-spaces;
			text-align: center;
			line-height: 24px;

			margin-top: 20px;
			margin-bottom: 30px;

			@media screen and (max-width: ${CSSVars.breakpointLG}) {
				margin-bottom: 20px;
			}
		}
	}

	// animations
	@keyframes move-to-left {
		from {
			transform: translateX(30px);
		}
		to {
			transform: translateX(0px);
		}
	}

	@keyframes swipe-to-left {
		from {
			opacity: 0;
			transform: translateX(50px);
		}
		to {
			opacity: 1;
			transform: translateX(30px);
		}
	}

	@keyframes move-to-right {
		from {
			transform: translateX(-30px);
		}
		to {
			transform: translateX(0);
		}
	}

	@keyframes swipe-to-right {
		from {
			opacity: 0;
			transform: translateX(-50px);
		}
		to {
			opacity: 1;
			transform: translateX(-30px);
		}
	}

	@keyframes left-shadow-float {
		0% {
			transform: rotateX(30deg) rotateY(-20deg) translateY(0px);
		}
		50% {
			transform: rotateX(30deg) rotateY(-20deg) translateY(-10px);
		}
		100% {
			transform: rotateX(30deg) rotateY(-20deg) translateY(0px);
		}
	}

	@keyframes images-float {
		0% {
			transform: translateY(0px);
		}
		50% {
			transform: translateY(-10px);
		}
		100% {
			transform: translateY(0px);
		}
	}

	@keyframes right-shadow-float {
		0% {
			transform: rotateX(-32deg) rotateY(-20deg) translateY(0px);
		}
		50% {
			transform: rotateX(-32deg) rotateY(-20deg) translateY(-8px);
		}
		100% {
			transform: rotateX(-32deg) rotateY(-20deg) translateY(0px);
		}
	}
`;
