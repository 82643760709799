import { CSSVars } from "@/styles/styles.config";
import styled from "styled-components";

export const StyledFeaturesSection = styled.section`
  margin: 0 auto;

  .feature-container-mobile {
    height: fit-content;
    display: block;
    display: flex;
    flex-direction: column;
    gap: 100px;

    .feature-card {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      transition: 0.3s;
      gap: 30px;
      padding: 0 20px;

      img {
        max-width: 100%;
        object-fit: contain;
      }

      .feature-description {
        text-align: left;
        font-size: ${CSSVars.fontSizeLG};
        display: flex;
        align-items: center;
        align-items: start;
        gap: 20px;
        transition: 0.2s;
        max-width: 590px;

        span {
          display: flex;
          font-size: ${CSSVars.fontSizeLG};
          align-items: center;
          justify-content: center;
          min-width: 36px;
          min-height: 36px;
          border-radius: 50em;
          border: 1px solid white;
          color: black;
          background-color: white;
        }

        .wrapper {
          display: flex;
          flex-direction: column;
          gap: 10px;
          font-size: ${CSSVars.fontSizeMD};

          h2 {
            margin: 0;
            line-height: 1.2;
            font-size: ${CSSVars.fontSize2XL};
          }

          p {
            margin: 0;
          }
        }
      }
    }

    .box-wrapper {
      padding: 0 20px;
    }

    .box-container {
      transition: 0.3s;
      margin: 0 auto;
      max-width: 100%;
      width: 500px;
      border-radius: 20px;
      z-index: 2;

      display: flex;
      align-items: center;
      justify-content: center;

      background: linear-gradient(
        180deg,
        #000 6.49%,
        #4c4fff 75.1%,
        #ff979c 100%
      );
      filter: drop-shadow(0px 0px 20px rgba(255, 255, 255, 0.1));

      padding: 1px;
      background-origin: border-box;
      background-clip: content-box, border-box;
      background-image: linear-gradient(
          180deg,
          #000 6.49%,
          #4c4fff 75.1%,
          #ff979c 100%
        ),
        linear-gradient(180deg, #66f, #ff979c);

      aspect-ratio: unset;
      height: fit-content;

      &.expand {
        align-items: flex-start;
        top: 0;
        width: 100%;
        height: 1000px;
        border-radius: 0;
        background-image: linear-gradient(
            180deg,
            #000 6.49%,
            #4c4fff 35.1%,
            #ff979c 70%,
            #fff 100%
          ),
          linear-gradient(180deg, transparent, white);

        .text-container {
          margin-top: -12%;
          padding: 200px 20px 50px;

          h3.title {
            max-width: 700px;
          }
        }
      }

      .text-container {
        padding: 50px 20px;
        display: flex;
        position: relative;
        flex-direction: column;
        gap: 10px;

        h3.title {
          font-size: ${CSSVars.fontSize2XL};
          margin: 0 auto 20px;
          max-width: 420px;
          line-height: 1.3;

          @media screen and (max-width: 700px) {
            margin: 0 auto 10px;
            font-size: ${CSSVars.fontSizeXL};
          }
        }

        .description {
          font-size: ${CSSVars.fontSize2XL};
          font-style: normal;
          margin: 0;
          text-align: center;

          @media screen and (max-width: 700px) {
            font-size: ${CSSVars.fontSizeLG};
            margin-top: 20px;
          }
        }

        h3.text-overflow {
          margin: 0;
          display: flex;
          color: white;
          white-space: nowrap;
          text-align: center;

          font-size: ${CSSVars.fontSize2XL};
          gap: 10px;
          flex-direction: column;

          span:last-of-type {
            font-weight: 500;
          }
        }
      }
    }

    .integration-animation-wrapper {
      pointer-events: none;
      margin: 0px;

      .mobile {
        height: 100px;
      }
    }

    .integration-container {
      padding: 0 20px;
      text-align: center;
      margin-bottom: -250px;
      z-index: 5;

      h3 {
        color: ${CSSVars.white};
        font-size: ${CSSVars.fontSizeXL};
        font-weight: 300;
        margin-bottom: 10px;
      }

      p {
        font-size: ${CSSVars.fontSizeMD};
        font-weight: 300;
      }
    }

    @media screen and (min-width: ${CSSVars.breakpointSM}) {
      display: none;
    }
  }

  .feature-container-desktop {
    max-width: 1240px;
    margin: 0 auto;
    height: 10500px;
    position: relative;
    transition: 0.3s;
    padding: 0 20px;

    display: none;
    @media screen and (min-width: ${CSSVars.breakpointSM}) {
      display: block;
    }

    &.expand {
      max-width: 100%;
      padding: 0;
    }

    .feature-card {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 30px;
      position: sticky;
      transition: 0.3s;
      border: none;
      top: 15%;
      z-index: 1;

      &.center {
        top: 50%;
        transform: translateY(-50%);
      }

      @media screen and (max-width: 1100px) {
        top: 12%;
        flex-direction: column;

        &.center {
          top: 12%;
          transform: translateY(0);
        }

        &:has(.expand) {
          /* top: 4%; */
        }
      }

      .number-tabs {
        display: flex;
        flex-direction: column;
        gap: 20px;
        transition: 0.2s;

        &.hide {
          pointer-events: none;
          opacity: 0;
        }

        @media screen and (max-width: 1100px) {
          display: none;
        }

        button {
          color: white;
          opacity: 0.6;
          font-size: ${CSSVars.fontSizeLG};
          border: none;
          background-color: transparent;
          display: flex;
          align-items: center;
          gap: 10px;
          font-family: inherit;
          transition: 0.3s;
          font-weight: 300;

          span {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 36px;
            height: 36px;
            border-radius: 50em;
            border: 1px solid white;
            color: white;
          }

          &.active {
            opacity: 1;

            span {
              color: black;
              background-color: white;
            }
          }
        }
      }
    }

    .animation-wrapper {
      transition: 0.3s;
      display: block;
      transform: translate(0, 0);

      /* Video Animation */
      div > canvas {
        width: 590px !important;
        height: auto !important;
        max-width: 100%;
        aspect-ratio: 590 / 350;
      }

      &.move-to-center {
        transform: translate(100px, 0);

        @media screen and (max-width: 1100px) {
          transform: translate(0, 0);
        }
      }

      &.hide {
        opacity: 0;
        display: none;
      }
    }

    .feature-description {
      text-align: left;
      max-width: 350px;
      font-size: ${CSSVars.fontSizeLG};
      display: flex;
      align-items: center;
      align-items: start;
      gap: 20px;
      opacity: 1;
      transition: 0.2s;

      &.hide {
        pointer-events: none;
        opacity: 0;
      }

      &:not(.hide) {
        &.active {
          animation: fade-in 0.8s;
        }
      }

      span {
        display: none;
        font-size: ${CSSVars.fontSizeLG};
        align-items: center;
        justify-content: center;
        min-width: 36px;
        min-height: 36px;
        border-radius: 50em;
        border: 1px solid white;
        color: black;
        background-color: white;
      }

      @media screen and (max-width: 1100px) {
        max-width: 590px;

        span {
          display: flex;
        }
      }

      .wrapper {
        display: flex;
        flex-direction: column;
        gap: 20px;

        @media screen and (max-width: 1100px) {
          gap: 10px;
          font-size: ${CSSVars.fontSizeMD};
        }

        h2 {
          margin: 0;
          line-height: 1.2;
          font-size: ${CSSVars.fontSize3XL};

          @media screen and (max-width: 1200px) {
            font-size: ${CSSVars.fontSize2XL};
          }
        }

        p {
          margin: 0;
        }
      }
    }

    .integration-animation-wrapper {
      position: absolute;
      transition: 0.5s;
      opacity: 0;
      pointer-events: none;
      z-index: -1;
      scale: 0.3;

      &.show {
        z-index: 1;
        opacity: 1;
        scale: 1.3;
      }

      .desktop {
        display: block;

        canvas {
          width: 1200px !important;
          height: auto !important;
          max-width: 100%;
          aspect-ratio: 1200 / 770;
        }
      }

      .mobile {
        display: none;

        canvas {
          width: 390px !important;
          height: auto !important;
          max-width: 100%;
          aspect-ratio: 390 / 75;
        }
      }

      @media screen and (max-width: 1440px) {
        &.show {
          scale: 1.1;
        }
      }

      @media screen and (max-width: 1000px) {
        bottom: calc(100% + 7px);
        z-index: 10;
        scale: 0.8;
        .desktop {
          display: none;
        }

        .mobile {
          display: block;
        }

        &.show {
          scale: 1;
        }
      }
    }

    .box-container {
      position: sticky;
      transition: 0.3s;
      max-width: 1200px;
      margin: 0 auto;
      max-width: 100%;
      width: 500px;
      height: auto;
      aspect-ratio: 500 / 360;
      border-radius: 20px;
      z-index: 2;

      display: flex;
      align-items: center;
      justify-content: center;

      background: linear-gradient(
        180deg,
        #000 6.49%,
        #4c4fff 75.1%,
        #ff979c 100%
      );
      filter: drop-shadow(0px 0px 20px rgba(255, 255, 255, 0.1));

      padding: 1px;
      background-origin: border-box;
      background-clip: content-box, border-box;
      background-image: linear-gradient(
          180deg,
          #000 6.49%,
          #4c4fff 75.1%,
          #ff979c 100%
        ),
        linear-gradient(180deg, #66f, #ff979c);

      @media screen and (max-width: 500px) {
        aspect-ratio: unset;
        height: fit-content;

        .text-container {
          padding: 50px 20px;
        }
      }

      .text-container {
        display: flex;
        position: relative;
        flex-direction: column;
        gap: 10px;

        h3.title {
          font-size: ${CSSVars.fontSize2XL};
          margin: 0 auto 20px;
          max-width: 420px;
          line-height: 1.3;

          @media screen and (max-width: 700px) {
            margin: 0 auto 10px;
            font-size: ${CSSVars.fontSizeXL};
          }
        }

        .description {
          font-size: ${CSSVars.fontSize2XL};
          font-style: normal;
          margin: 0;
          text-align: center;

          @media screen and (max-width: 700px) {
            font-size: ${CSSVars.fontSizeLG};
            margin-top: 20px;
          }
        }

        h3.text-overflow {
          font-size: ${CSSVars.fontSize4XL};
          margin: 0;
          display: flex;
          gap: 51px;
          color: white;
          white-space: nowrap;
          text-align: center;

          @media screen and (max-width: 1200px) {
            gap: 25px;
          }

          @media screen and (max-width: 700px) {
            font-size: ${CSSVars.fontSize3XL};
          }

          @media screen and (max-width: 500px) {
            font-size: ${CSSVars.fontSize2XL};
            gap: 10px;
            flex-direction: column;
          }

          span:last-of-type {
            font-weight: 500;
          }
        }
      }

      &.hide {
        transition: 0s;
        position: absolute;
        pointer-events: none;
        opacity: 0;
        top: 20px;
      }

      &.resize {
        width: 625px;
        height: 450px;
        text-align: center;
        filter: none;

        @media screen and (max-width: 1200px) {
          aspect-ratio: unset;
          height: fit-content;
        }

        .text-container {
          padding: 80px 50px;

          @media screen and (max-width: 700px) {
            padding: 50px 20px;
          }
        }

        .description {
          font-size: ${CSSVars.fontSizeMD};
          max-width: 550px;
          margin: 0 auto;
        }
      }

      &.expand {
        align-items: flex-start;
        top: 0;
        width: 100%;
        height: 1000px;
        border-radius: 0;
        background-image: linear-gradient(
            180deg,
            #000 6.49%,
            #4c4fff 35.1%,
            #ff979c 70%,
            #fff 100%
          ),
          linear-gradient(180deg, transparent, white);

        .text-container {
          margin-top: -12%;

          h3.title {
            max-width: 700px;
          }
        }
      }
    }
  }

  .integrations-button {
    color: white;

    &::before {
      background-color: white;
    }
  }

  .complete-control-section {
    background-color: white;
  }

  @keyframes fade-in {
    from {
      opacity: 0;
      transform: translateY(5px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;
