import { CSSVars } from "@/styles/styles.config";
import styled from "styled-components";

export const StyledPlatformSupport = styled.div`
  padding: 550px 0 300px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -5px;
  background: white;

  .description-container {
    color: var(--black, #010101);
    transform: translateY(15%);
    z-index: 2;
    width: 600px;
    max-width: 100%;
    margin: auto;
    text-align: center;
    position: absolute;

    .platform-support-title {
      display: block;
      margin: auto;
      font-size: ${CSSVars.fontSize5XL};
      position: relative;
      line-height: 120px;

      &::before {
        content: "";
        background: linear-gradient(
          90deg,
          rgba(245, 245, 245, 0) 0%,
          white 10%,
          white 90%,
          rgba(255, 255, 255, 0) 100%
        );

        width: 120%;
        height: 80%;
        position: absolute;
        left: -10%;
        transform: translateY(17%);

        z-index: -1;
      }
    }
    .platform-support-description {
      font-size: ${CSSVars.fontSizeLG};
      margin-top: 20px;
    }
    .platform-support-button {
      margin-top: 30px;
    }

    @media screen and (max-width: ${CSSVars.breakpointMD}) {
      transform: translateY(0);
      padding: 0 20px;

      .platform-support-title {
        font-size: ${CSSVars.fontSize3XL};
        line-height: 60px;

        &::before {
          display: none;
        }
      }

      .platform-support-description {
        margin-top: 10px;
      }

      .platform-support-button {
        margin-top: 20px;
      }
    }
  }

  @media screen and (max-width: 1100px) {
    padding: 650px 0 250px;
  }

  @media screen and (max-width: ${CSSVars.breakpointLG}) {
    padding: 55% 0 200px;
  }
  @media screen and (max-width: ${CSSVars.breakpointMD}) {
    padding: 350px 0 0px;
  }
`;
