import styled from 'styled-components';
import { CSSVars } from '../../styles/styles.config';

export const StyledDiv = styled.div`
	position: relative;
	height: 1200px;
	overflow: hidden;
	background-color: white;

	&::after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		height: 300px;
		background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, white 100%);
		pointer-events: none;
	}

	h2 {
		transform: translateY(150px);
		position: sticky;
		top: 200px;
		pointer-events: none;
		z-index: 10;
		font-weight: 400;
		color: ${CSSVars.black};
		font-size: ${CSSVars.fontSize3XL};
		text-align: center;
		text-shadow: 0px 0px 30px rgba(255, 255, 255, 0.9);

		@media screen and (min-width: ${CSSVars.breakpointMD}) {
			font-size: ${CSSVars.fontSize4XL};
		}
	}

	.images-container {
		position: absolute;
		display: flex;
		gap: 30px;
		justify-content: center;
		bottom: 100%;
		left: 0;
		right: 0;
		top: 100px;
		
		@media screen and (min-width: ${CSSVars.breakpointMD}) {
			top: 50px;
		}
		/* transition: 1s ease-out; */

		.column {
			display: flex;
			flex-direction: column;
			gap: 30px;

			.image-item {
				cursor: pointer;
				position: relative;
				display: block;

				&:hover {
					.overlay {
						background-color: rgba(255, 255, 255, 0.1);
					}
				}

				.overlay {
					display: block;
					transition: 0.3s;
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					background-color: rgba(255, 255, 255, 0.6);
					border-radius: 10px;
				}

				img {
					pointer-events: none;
				}
			}
		}
	}
`;
