"use client";

import { numbersHelper } from "../numbers/numbers.helper";
import { StyledReviews } from "./reviews.styles";
import { IoIosArrowForward } from "@react-icons/all-files/io/IoIosArrowForward";
import { useEffect, useState } from "react";
import { CustomLink } from "@/components/link/link.comp";
import { CommonNinjaWidgetRenderer } from '@/components/CommonNinjaWidgetRenderer/commonNinjaWidgetRenderer.comp';

interface IReviewsProps {
  mode?: 'light' | 'dark';
}

export const Reviews = ({ mode = 'light' }: IReviewsProps) => {
  const [isMobile, setIsMobile] = useState(
    typeof window === "undefined" ? false : window.innerWidth < 1100
  );

  useEffect(() => {
    const resizeHandler = () => {
      setIsMobile(window.innerWidth < 1100);
    };
    
    window.addEventListener("resize", resizeHandler);
    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, []);

  const widgetId = (mode === 'dark') ? '6b88abbb-dc15-47a5-9d2c-f059f5d7ba15' : '93fee74d-1f7b-425a-9242-43051c86edca';

  return (
    <StyledReviews className={mode}>
      <h4 className="reviews-title">{`Don't take it from us`}</h4>
      <p className="reviews-description">{`${numbersHelper.widgetUsedByBusinesses} Businesses use our widgets on their websites & online stores.`}</p>
      <CustomLink
        variant="underline"
        href="https://community.commoninja.com/"
        className="reviews-button"
      >
        Join our community
        <IoIosArrowForward />
      </CustomLink>

      <div>
        <CommonNinjaWidgetRenderer widgetId={widgetId} />
      </div>
    </StyledReviews>
  );
};
