import React, { ReactNode } from "react";
import { CSSProperties } from "styled-components";
import { StyledSection } from "./section.styles";

export const Section = ({
  children,
  style = {},
  mode = "light",
  ref,
}: {
  children: ReactNode;
  style?: CSSProperties;
  mode?: "dark" | "light";
  ref?: React.LegacyRef<HTMLDivElement> | undefined;
}) => {
  return (
    <StyledSection ref={ref} style={style} className={mode}>
      {children}
    </StyledSection>
  );
};
