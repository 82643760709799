import styled from "styled-components";
import { CSSVars } from "@/styles/styles.config";

export const StyledReviews = styled.div`
  color: var(--black, #010101);
  padding: 50px 0;
  overflow-x: hidden;

  &.dark {
    .reviews-title,
    .reviews-description {
      color: ${CSSVars.white};
    }

    a {
      color: ${CSSVars.blue400};
    }
  }
  
  @media screen and (min-width: ${CSSVars.breakpointLG}) {
    padding: 100px 0;
  }

  .reviews-title,
  .reviews-description {
    text-align: center;
    padding: 0 20px;
  }

  .reviews-title {
    line-height: 60px;
    font-size: ${CSSVars.fontSize2XL};

    @media screen and (min-width: ${CSSVars.breakpointMD}) {
      font-size: ${CSSVars.fontSize3XL};
    }
  }
  .reviews-description {
    font-size: ${CSSVars.fontSizeLG};
    margin-top: 20px;
  }
`;
