/* eslint-disable @next/next/no-img-element */
"use client";

import { CSSProperties } from "styled-components";
import { StyledParallaxEditor } from "./parallax-editor.styles";
import { useRef, useState } from "react";

interface IParallaxEditorProps {
  style?: CSSProperties;
  wrapperStyle?: CSSProperties;
  widgetImgSrc?: string;
  contentImgSrc?: string;
  editorContainerImgSrc?: string;
  skinsImgSrc?: string;
  widgetImgStyle?: CSSProperties;
  contentImgStyle?: CSSProperties;
  editorContainerImgStyle?: CSSProperties;
  skinsImgStyle?: CSSProperties;
  hideLayoutsImg?: boolean;
  hideWidgetImg?: boolean;
  hideContentImg?: boolean;
  hideEditorContainerImg?: boolean;
}

export const ParallaxEditor = ({
  style,
  wrapperStyle,
  widgetImgSrc,
  hideContentImg,
  hideEditorContainerImg,
  hideWidgetImg,
  editorContainerImgStyle,
  editorContainerImgSrc,
  skinsImgSrc,
  widgetImgStyle,
  contentImgSrc,
  contentImgStyle,
  skinsImgStyle,
  hideLayoutsImg,
}: IParallaxEditorProps) => {
  const cardRef = useRef<any>();
  const [dimensions, setDimensions] = useState({ x: 0, y: 0 });

  const handleMouseMove = (e: any) => {
    if (window.innerWidth <= 768) {
      return setDimensions({ x: 0, y: 0 });
    }
    const card: any = cardRef.current;
    const { width, height, left, top } = card.getBoundingClientRect();
    const midWidth = width / 2;
    const midHeight = height / 2;
    const delta = 80;

    const cursPosX = e.pageX - left;
    const cursPosY = e.pageY - top;
    const cursCenterX = midWidth - cursPosX;
    const cursCenterY = midHeight - cursPosY;

    setDimensions({ x: cursCenterY / delta, y: -(cursCenterX / delta) });
  };

  const handleMouseLeave = () => {
    setDimensions({ x: 0, y: 0 });
  };

  return (
    <div
      onMouseMove={handleMouseMove}
      onMouseOver={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      ref={cardRef}
      style={{ overflow: "hidden", padding: "0 0 50px", ...wrapperStyle }}
    >
      <StyledParallaxEditor
        style={
          {
            "--rotate-x": dimensions.x + "deg",
            "--rotate-y": dimensions.y + "deg",

            "--translate-x": dimensions.y * 5 + "px",
            "--translate-y": dimensions.x * -3 + "px",

            "--translate-plugin-x": dimensions.y * 3 + "px",
            "--translate-plugin-y": dimensions.x * -1.2 + "px",

            "--rotate-plugin-x": dimensions.x * 0.8 + "deg",
            ...style,
          } as CSSProperties
        }
        className="parallax-container"
      >
        {!hideWidgetImg && (
          <img
            className="plugin"
            src={widgetImgSrc || "/assets/parallax/plugin.webp"}
            alt="plugin"
            style={widgetImgStyle}
          />
        )}
        {!hideEditorContainerImg && (
          <img
            className="editor-container"
            src={editorContainerImgSrc || "/assets/parallax/editor.webp"}
            alt="editor"
            style={editorContainerImgStyle}
          />
        )}
        {!hideContentImg && (
          <img
            className="editor-dashboard"
            src={contentImgSrc || "/assets/parallax/editor-left.webp"}
            alt="editor"
            style={contentImgStyle}
          />
        )}
        <div className="layouts-and-skins">
          <img
            className="skins"
            src={skinsImgSrc || "/assets/parallax/skins.webp"}
            alt="skins"
            style={skinsImgStyle}
          />
          {!hideLayoutsImg && (
            <img
              className="layouts"
              src={"/assets/parallax/layouts.webp"}
              alt="layouts"
            />
          )}
        </div>
      </StyledParallaxEditor>
    </div>
  );
};
