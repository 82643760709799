'use client';
import React from 'react';
import { StyleTitleProposition } from './titlePropositions.styles';

export const TitlePropositions = () => {
	return (
		<StyleTitleProposition>
			<h1>Just Pick, Customize, and Design</h1>
		</StyleTitleProposition>
	);
};
