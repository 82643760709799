"use client";

import Image from "next/image";
import { IoIosArrowForward } from "@react-icons/all-files/io/IoIosArrowForward";
import Link from "next/link";
import { useEffect, useState } from "react";
import { Section } from "../section/section.comp";
import { StyledAnalyticsEditor } from "./analytics.styles";

import Rive, { Alignment, Fit, Layout } from "@rive-app/react-canvas";
import { useSpring, animated } from "@react-spring/web";
import { CustomLink } from "../link/link.comp";

export const AnalyticsEditor = () => {
  const [isMobile, setIsMobile] = useState(
    typeof window === "undefined" ? false : window.innerWidth <= 1100
  );

  const titleSpring = useSpring({
    from: { opacity: 0, transform: "translateY(20px)" },
    to: {
      opacity: 1,
      transform: "translateY(0px)"
    },
    delay: 100,
  });

  const descriptionSpring = useSpring({
    from: { opacity: 0, transform: "translateY(20px)" },
    to: {
      opacity: 1,
      transform: "translateY(0px)"
    },
    delay: 200,
  });

  const buttonSpring = useSpring({
    from: { opacity: 0, transform: "translateY(20px)" },
    to: {
      opacity: 1,
      transform: "translateY(0px)"
    },
    delay: 300,
  });

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1100);
    };

    window?.addEventListener("resize", handleResize);

    return () => {
      window?.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Section>
      <StyledAnalyticsEditor className="analytics-wrapper">
        <div className="analytics-header-container">
          <div className="header-description-container">
            <animated.h3 style={titleSpring} className="analytics-title">
              An entire ecosystem of Widgets+ is in the business of helping you
              to grow yours
            </animated.h3>
            <animated.p style={descriptionSpring} className="analytics-description">
              Your chosen widgets connect to each other to form the smartest
              network of analytics collection, helping your act smarter and
              improve your business.
            </animated.p>

            <animated.div
              style={buttonSpring}
            >
              <CustomLink variant="underline" href="/widgets" className="analytics-button">
                Discover More
                <IoIosArrowForward />
              </CustomLink>
            </animated.div>
          </div>

          {isMobile ? (
            <>
              <Rive
                className="canvas-top"
                src="https://website-assets.commoninja.com/assets/mobile-nodes.riv"
                layout={
                  new Layout({
                    fit: Fit.Contain,
                    alignment: Alignment.Center,
                  })
                }
                animations={"Nodes"}
                artboard="Top"
                style={{
                  aspectRatio: 320 / 200,
                  opacity: "75%",
                  maxWidth: "100%",
                  margin: "auto",
                }}
              />
              <Rive
                className="canvas-bottom"
                src="https://website-assets.commoninja.com/assets/mobile-nodes.riv"
                layout={
                  new Layout({
                    fit: Fit.Contain,
                    alignment: Alignment.Center,
                  })
                }
                animations={"Nodes"}
                artboard="Bottom"
                style={{
                  aspectRatio: 320 / 200,

                  opacity: "75%",
                  maxWidth: "100%",
                  margin: "auto",
                }}
              />
            </>
          ) : (
            <Rive
              src="https://website-assets.commoninja.com/assets/nodes-animation+(2).riv"
              layout={
                new Layout({
                  fit: Fit.Contain,
                  alignment: Alignment.Center,
                })
              }
              animations={"Nodes"}
              style={{
                aspectRatio: 320 / 200,

                opacity: "75%",
                maxWidth: "100%",
                margin: "auto",
              }}
            />
          )}
        </div>
        <div className="analytics-wrapper">
          <Image
            src="/assets/analystic/analytics-screen.png"
            alt="analytics screen"
            className="analytics-screen"
            width={500}
            height={300}
          />
          <Image
            src="/assets/analystic/impressions-vs-views.png"
            alt="impressions vs views"
            className="impressions-vs-views"
            width={200}
            height={200}
          />
          <Image
            src="/assets/analystic/impressions.png"
            alt="impressions"
            className="impressions"
            width={200}
            height={200}
          />
          <Image
            src="/assets/analystic/pageviews.png"
            alt="pageviews"
            className="pageviews"
            width={200}
            height={200}
          />
        </div>
        <div className="analytics-footer-container">
          <h3 className="analytics-title">Complete control under one roof</h3>
          <p className="analytics-description">
            Every website goal and project can kick ass, with one deep platform
            that gives you access to oversight, insight and managing all your
            Widgets+
          </p>
          <CustomLink variant="underline" href="/features/analytics" className="analytics-button">
            Discover More
            <IoIosArrowForward />
          </CustomLink>
        </div>
      </StyledAnalyticsEditor>
    </Section >
  );
};
