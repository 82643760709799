"use client";

import { CommonNinjaWidgetRenderer } from "../CommonNinjaWidgetRenderer/commonNinjaWidgetRenderer.comp";
import { StyledPlatformSupport } from "./platformSupport.styles";
import { CustomLink } from "../link/link.comp";

export const PlatformSupport = () => {
  return (
    <StyledPlatformSupport>
      <div className="description-container">
        <h2 className="platform-support-title">Plays well with friends</h2>
        <p className="platform-support-description">
          {`Being this popular is uncommon. No matter your platform provider or
          site builder... we're sure we're connected.`}
        </p>
        <CustomLink  variant="gradient" className="platform-support-button" href={"/platforms"}>
          Find Your Platform
        </CustomLink>
      </div>
      <CommonNinjaWidgetRenderer
        widgetId={"fede5686-e495-480a-93ce-ff98b0460b17"}
      />
    </StyledPlatformSupport>
  );
};
