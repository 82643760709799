"use client";
import { useEffect, useRef, useState } from "react";
import { ParallaxProps, useParallax } from "react-scroll-parallax";
import { numbersHelper } from "./numbers.helper";
import { StyledNumbers } from "./numbers.styles";
import { CSSVars } from "@/styles/styles.config";

export const Numbers = () => {
  const sectionRef: any = useRef(null);

  const endPoint = Number(CSSVars.breakpointMD.split("px")[0]);

  const [isMobile, setIsMobile] = useState(
    typeof window === "undefined" ? false : window.innerWidth <= endPoint
  );

  const parallaxControls: ParallaxProps = {
    shouldAlwaysCompleteAnimation: true,
    disabled: isMobile,
  };

  // 50M+ widget interacted
  const widgetsInteracted = useParallax({
    translateY: ["40%", "-40%"],
    rotateY: ["20deg", "0deg"],
    rotateX: ["20deg", "-10deg"],
    ...parallaxControls,
  });

  // 1M+ - widget created
  const widgetsCreated = useParallax({
    translateY: ["50%", "-35%"],
    rotateY: ["10deg", "0deg"],
    rotateX: ["25deg", "-5deg"],
    ...parallaxControls,
  });

  // 500K+ - used by businesses
  const usedByBusinesses = useParallax({
    translateY: ["0%", "-70%"],
    rotateY: ["8deg", "0deg"],
    rotateX: ["20deg", "-5deg"],
    ...parallaxControls,
  });

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= endPoint);
    };

    window?.addEventListener("resize", handleResize);

    return () => {
      window?.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div style={{ padding: "50px 20px" }} ref={sectionRef}>
      <StyledNumbers>
        <h2>{`Size doesn't matter but...`}</h2>
        <div className="cards-container">
          <div ref={widgetsCreated.ref as any} className="card widget-created">
            <h3>{numbersHelper.widgetCreated}</h3>
            <p>Widgets have been created with Common Ninja</p>
            {widgets}
          </div>
          <div
            ref={widgetsInteracted.ref as any}
            className="card widget-interacted"
          >
            <h3>{numbersHelper.widgetInteracted}</h3>
            <p>Users interact with our widgets every month</p>
            {users}
          </div>
          <div
            ref={usedByBusinesses.ref as any}
            className="card used-by-businesses"
          >
            <h3>{numbersHelper.widgetUsedByBusinesses}</h3>
            <p>Businesses use our widgets on their websites & online stores</p>
            {businesses}
          </div>
        </div>
      </StyledNumbers>
    </div>
  );
};

const users = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="80"
    height="62"
    viewBox="0 0 80 62"
    fill="none"
  >
    <path
      d="M79.4009 21.4401C79.4009 15.238 74.3731 10.2102 68.171 10.2102H35.9253C29.7232 10.2102 24.6954 15.238 24.6954 21.4401V50.171C24.6954 56.3732 29.7232 61.401 35.9253 61.401H68.171C74.3731 61.401 79.4009 56.3732 79.4009 50.171V21.4401Z"
      fill="white"
    />
    <path
      d="M79.4009 21.4401C79.4009 15.238 74.3731 10.2102 68.171 10.2102H35.9253C29.7232 10.2102 24.6954 15.238 24.6954 21.4401V50.171C24.6954 56.3732 29.7232 61.401 35.9253 61.401H68.171C74.3731 61.401 79.4009 56.3732 79.4009 50.171V21.4401Z"
      fill="url(#paint0_linear_5199_2895)"
      fillOpacity="0.3"
    />
    <path
      d="M79.4009 21.4401C79.4009 15.238 74.3731 10.2102 68.171 10.2102H35.9253C29.7232 10.2102 24.6954 15.238 24.6954 21.4401V50.171C24.6954 56.3732 29.7232 61.401 35.9253 61.401H68.171C74.3731 61.401 79.4009 56.3732 79.4009 50.171V21.4401Z"
      stroke="black"
      strokeWidth="1.19813"
    />
    <path
      d="M38.9195 4.79254C38.9195 2.47655 37.042 0.599067 34.726 0.599067H21.1785C18.8625 0.599067 16.9851 2.47655 16.9851 4.79253V17.309C16.9851 19.625 18.8625 21.5025 21.1785 21.5025L34.726 21.5025C37.042 21.5025 38.9195 19.625 38.9195 17.3091V4.79254Z"
      fill="url(#paint1_linear_5199_2895)"
      stroke="black"
      strokeWidth="1.19813"
    />
    <path
      d="M15.7867 29.6031C15.7867 27.2871 13.9092 25.4096 11.5932 25.4096L4.79273 25.4096C2.47674 25.4096 0.599246 27.287 0.599246 29.603V35.5709C0.599246 37.8869 2.47671 39.7644 4.7927 39.7644L11.5932 39.7644C13.9092 39.7644 15.7867 37.887 15.7867 35.571V29.6031Z"
      fill="white"
      stroke="black"
      strokeWidth="1.19813"
    />
    <path
      d="M43.9713 30.8617C43.9713 35.3061 47.5853 38.912 52.047 38.912C56.5087 38.912 60.1226 35.3061 60.1226 30.8617C60.1226 26.4173 56.5087 22.8115 52.047 22.8115C47.5853 22.8115 43.9713 26.4173 43.9713 30.8617Z"
      stroke="black"
      strokeWidth="1.19813"
    />
    <path
      d="M31.886 61.2275C31.9823 52.205 40.9062 44.9846 51.8182 45.1003C62.7302 45.216 71.4982 52.624 71.4019 61.6465"
      stroke="black"
      strokeWidth="1.19813"
    />
    <defs>
      <linearGradient
        id="paint0_linear_5199_2895"
        x1="50.1214"
        y1="47.6396"
        x2="29.137"
        y2="9.28999"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4C4FFF" stopOpacity="0" />
        <stop offset="1" stopColor="#4C4FFF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_5199_2895"
        x1="27.9523"
        y1="3.05505e-07"
        x2="39.4579"
        y2="34.1372"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
    </defs>
  </svg>
);

const businesses = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="80"
    height="74"
    viewBox="0 0 80 74"
    fill="none"
  >
    <path
      d="M0.5 6.00016C0.5 2.96259 2.96243 0.500163 6 0.500161L74 0.500112C77.0376 0.50011 79.5 2.96254 79.5 6.00011V48.3918C79.5 51.4294 77.0376 53.8918 74 53.8918L6.00001 53.8918C2.96244 53.8918 0.5 51.4294 0.5 48.3918V6.00016Z"
      fill="white"
      stroke="black"
    />
    <path
      d="M12.8809 15.067C12.8809 12.0294 15.3433 9.56695 18.3809 9.56695L43.5237 9.56695C46.5613 9.56695 49.0237 12.0294 49.0237 15.0669V30.2628C49.0237 33.3004 46.5613 35.7628 43.5237 35.7628H18.3809C15.3433 35.7628 12.8809 33.3003 12.8809 30.2628V15.067Z"
      fill="url(#paint0_linear_5199_2905)"
      stroke="black"
    />
    <path
      d="M36.6154 26.814L29.9017 70.538C29.749 71.5323 30.9948 72.1043 31.6494 71.3405L43.242 57.8137C43.4945 57.519 43.8947 57.397 44.2687 57.5008L61.8887 62.3909C62.7965 62.6428 63.5173 61.6144 62.9706 60.8471L38.4182 26.3855C37.8974 25.6544 36.7516 25.9267 36.6154 26.814Z"
      fill="white"
      stroke="black"
    />
    <defs>
      <linearGradient
        id="paint0_linear_5199_2905"
        x1="30.9523"
        y1="2.86439"
        x2="12.3181"
        y2="50.0745"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF979C" stopOpacity="0" />
        <stop offset="1" stopColor="#FF979C" />
      </linearGradient>
    </defs>
  </svg>
);

const widgets = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
  >
    <path
      d="M63.5 22.1978C63.5 17.0213 59.3036 12.825 54.1271 12.825H18.4678C13.2913 12.825 9.09493 17.0213 9.09493 22.1978V54.1273C9.09493 59.3038 13.2913 63.5002 18.4678 63.5002L54.1271 63.5002C59.3036 63.5002 63.5 59.3038 63.5 54.1273V22.1978Z"
      fill="url(#paint0_linear_5199_2913)"
      stroke="black"
    />
    <path
      d="M54.9053 9.87286C54.9053 4.69637 50.7089 0.5 45.5324 0.5H9.87272C4.69624 0.5 0.499863 4.69637 0.499863 9.87285V41.8023C0.499863 46.9788 4.69623 51.1752 9.87271 51.1752L45.5324 51.1752C50.7089 51.1752 54.9053 46.9788 54.9053 41.8024V9.87286Z"
      fill="url(#paint1_linear_5199_2913)"
      stroke="black"
    />
    <path d="M39.8145 39.4942L13.5448 11.0559" stroke="black" />
    <path d="M34.082 10.5864L13.5439 11.0604L11.6334 30.4932" stroke="black" />
    <defs>
      <linearGradient
        id="paint0_linear_5199_2913"
        x1="36.2975"
        y1="12.325"
        x2="63.6311"
        y2="83.8648"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.258569" stopColor="#FF979C" stopOpacity="0" />
        <stop offset="1" stopColor="#FF979C" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_5199_2913"
        x1="39.1659"
        y1="18.0109"
        x2="55.174"
        y2="77.7932"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
    </defs>
  </svg>
);
