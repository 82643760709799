"use client";

import { CommonNinjaWidget } from "commonninja-react";
import { CSSProperties } from 'react';

export const CommonNinjaWidgetRenderer = ({
  widgetId,
  widgetProps,
  style,
}: {
  widgetId: string;
  widgetProps?: string;
  style?: CSSProperties;
}) => {
  return (
    <CommonNinjaWidget
      widgetId={widgetId}
      widgetProps={widgetProps}
      style={style}
      lazyLoad
    />
  );
};
