import { CSSVars } from "@/styles/styles.config";
import styled from "styled-components";

export const StyledAnalyticsEditor = styled.div`
  margin: auto;

  .analytics-wrapper {
    margin: auto;
    width: 50%;
    padding: 15px;
    border-radius: 15px;
    border: 1px solid #fff;
    position: relative;

    animation: fade-in-full-opacity 1.5s ease-in-out;

    .analytics-screen {
      width: 100%;
      height: auto;
      margin: auto;
    }

    .impressions-vs-views,
    .impressions,
    .pageviews {
      position: absolute;
      height: auto;
    }

    .impressions {
      width: 31%;
      top: -4%;
      left: 15.5%;
      animation: float 4s linear infinite;
    }
    .impressions-vs-views {
      width: 33%;
      top: 23.5%;
      right: -5%;
      animation: float 4.5s linear reverse infinite;
    }

    .pageviews {
      width: 21%;
      top: 45%;
      left: -5%;
      animation: float 4.2s linear infinite;
    }
  }

  .analytics-footer-container {
    margin: auto;
    width: 55%;
    color: var(--black, #010101);
  }

  .analytics-title {
    margin: 30px 0 20px;

    text-align: center;
    font-size: ${CSSVars.fontSize2XL};
    line-height: 40px;
  }

  .analytics-description {
    text-align: center;
    font-size: ${CSSVars.fontSizeMD};
  }

  .analytics-button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;

    margin: 30px auto 0;

    width: max-content;

    color: ${CSSVars.blue500};
    font-size: ${CSSVars.fontSizeMD};
    font-weight: 500;
  }

  .canvas-desktop-center {
    aspect-ratio: 1220 / 800;

    width: 100%;
    opacity: 75%;
    max-width: 100%;
    margin: auto;

    animation: fade-in 1.5s ease-in-out;
  }

  @media screen and (max-width: 1100px) {
    width: 90%;
    margin-bottom: 200px;

    .analytics-wrapper,
    .analytics-footer-container {
      width: 100%;
    }
    .analytics-title {
      font-size: ${CSSVars.fontSizeXL};
      line-height: normal;
    }

    .analytics-title {
      margin-bottom: 10px;
    }

    .analytics-button {
      margin-top: 20px;
    }
  }

  .analytics-header-container {
    position: relative;
    display: flex;
    justify-content: center;

    .header-description-container {
      z-index: 2;
      position: absolute;
      width: 50%;
      top: 20%;

      color: white;

      .analytics-description {
        margin: auto;
        width: 90%;
      }
      .analytics-button {
        color: white;

        &::before{
          background-color: white;
        }
      }
    }

    @media screen and (max-width: ${CSSVars.breakpointXL}) {
      .header-description-container {
        top: 10%;
      }
    }

    @media screen and (max-width: 1100px) {
      flex-direction: column;
      align-items: center;

      .canvas-top {
        order: 0;
      }
      .canvas-bottom {
        order: 2;
      }

      .canvas-bottom,
      .canvas-top {
        aspect-ratio: 320 / 200;
        width: 90%;
        height: 100%;
        margin: auto;
        max-width: 60%;
      }

      .header-description-container {
        position: relative;
        order: 1;
        width: 100%;
        margin: -10% 0;

        .analytics-title {
          margin-top: 0%;
        }
      }
    }

    @media screen and (max-width: ${CSSVars.breakpointMD}) {
      .header-description-container {
        width: 100%;
      }

      .canvas-bottom,
      .canvas-top {
        max-width: 90%;
      }
    }
  }

  @keyframes float {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-3px);
    }
    100% {
      transform: translateY(0px);
    }
  }

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 75%;
    }
  }
  @keyframes fade-in-full-opacity {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;
