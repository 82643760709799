"use client";

import Link from "next/link";
import { StyledFooterBanner } from "./footerBanner.styles";
import { Footer } from "../footer/footer.comp";

export const FooterBanner = () => {
  return (
    <StyledFooterBanner>
      <div className="card-container">
        <h4 className="small-title">Take your website from expected</h4>
        <h2 className="large-title"></h2>
        <Link className="footer-banner-button" href={"/widgets"}>
          Start creating
        </Link>
      </div>

      <Footer homepageLayout={true}/>
    </StyledFooterBanner>
  );
};
