import styled from "styled-components";
import { CSSVars } from "../../../styles/styles.config";

export const StyledNumbers = styled.div`
  color: var(--black, #010101);
  text-align: center;
  font-weight: 300;
  padding: 300px 0;
  position: relative;

  width: 1200px;
  max-width: 100%;
  margin: auto;

  h2 {
    margin-bottom: 50px;

    font-size: ${CSSVars.fontSize4XL};
    line-height: 90px;

    @media screen and (max-width: ${CSSVars.breakpointMD}) {
      font-size: ${CSSVars.fontSizeXL};
    }
  }

  .cards-container {
    max-width: 1420px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 50px;

    .card {
      background: linear-gradient(
        313deg,
        rgba(255, 255, 255, 0.9) 0%,
        rgba(255, 255, 255, 0.72) 100%
      );

      width: 280px;
      max-width: 100%;

      border: 1px solid #dbdbdb;
      border-radius: 20px;

      flex: 300px;

      padding: 60px 20px 20px;

      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;

      position: absolute;

      &.widget-created {
        top: 30%;
        right: 0%;
        transform: translateY(0%) rotateY(10deg) rotateX(25deg);
      }

      &.widget-interacted {
        top: 3%;
        left: 38%;
        transform: translateY(0%) rotateY(20deg) rotateX(20deg);
      }

      &.used-by-businesses {
        top: 60%;
        left: 1%;
        transform: translateY(0%) rotateY(8deg) rotateX(20deg);
      }

      h3 {
        font-size: ${CSSVars.fontSize3XL};
        line-height: 60px;
      }

      p {
        font-size: ${CSSVars.fontSizeMD};
      }

      svg {
        position: absolute;
        top: 0;
        transform: translateY(-50%);
      }

      @media screen and (max-width: ${CSSVars.breakpointMD}) {
        position: relative;
        width: 100%;
        flex-basis: 100%;

        &.widget-created,
        &.widget-interacted,
        &.used-by-businesses {
          top: 0;
          right: 0;
          left: 0;
          transform: translateY(0) rotateY(0) rotateX(0);
        }
      }
    }
  }
  @media screen and (max-width: ${CSSVars.breakpointMD}) {
    padding:  0;

    .cards-container {
      gap: 80px;
    }
  }
`;
