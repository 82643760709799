/* eslint-disable @next/next/no-img-element */
"use client";

import cn from "classnames";
import Image from "next/image";
import { useEffect, useRef, useState } from "react";
import { CommonNinjaWidgetRenderer } from "../CommonNinjaWidgetRenderer/commonNinjaWidgetRenderer.comp";
import { CustomLink } from "../link/link.comp";
import { ParallaxEditor } from "../parallax-editor/parallax-editor.comp";
import { SocialProof } from "../socialProof/socialProof.comp";
import { StyledHero } from "./hero.styles";

export const Hero = () => {
  const ref: any = useRef<HTMLImageElement>();
  const elementRef = useRef(null);
  // const [isTextAnimated, setIsTextAnimated] = useState(false);
  const [firstRender, setFirstRender] = useState(false);
  const [firstScrollDown, setFirstScrollDown] = useState(false);
  const [isFirefox, setIsFirefox] = useState(false);

  // This cause performance issues with LCP in light house
  // useEffect(() => {
  //   const observer = new IntersectionObserver(
  //     ([entry]) => {
  //       if (entry.isIntersecting) {
  //         setIsTextAnimated(true);
  //       } else {
  //         setFirstScrollDown(true);
  //         setIsTextAnimated(false);
  //       }
  //     },
  //     { threshold: 0.1 } // Trigger when 10% of the element is visible
  //   );

  //   if (elementRef.current) {
  //     observer.observe(elementRef.current);
  //   }

  //   return () => {
  //     if (elementRef.current) {
  //       observer.unobserve(elementRef.current);
  //     }
  //   };
  // }, []);

  useEffect(() => {
    setFirstRender(true);

    const userAgent = navigator.userAgent.toLowerCase();
    setIsFirefox(userAgent.includes("firefox"));
  }, []);

  return (
    <>
      <StyledHero>
        <div className="hero-content-container">
          <div className="header-background-overlay">
            {/* left */}
            <div
              style={{ width: 59, height: 67 }}
              className="shadow-left-L left shadow"
            ></div>
            <div
              style={{ width: 30, height: 30 }}
              className="shadow-left-M a left shadow"
            ></div>
            <div
              style={{ width: 32, height: 33 }}
              className="shadow-left-M b left shadow"
            ></div>
            <div
              style={{ width: 21, height: 22 }}
              className="shadow-left-S left shadow"
            ></div>
            <Image
              src="/assets/hero/graph.png"
              alt="graph"
              className="graph"
              width={92}
              height={94}
            />
            <Image
              src="/assets/hero/star.png"
              alt="star"
              className="star"
              width={130}
              height={160}
            />
            <Image
              src="/assets/hero/watch.png"
              alt="watch"
              className="watch"
              width={75}
              height={76}
            />

            {/* right */}
            <div
              style={{ width: 71, height: 82 }}
              className="shadow-right-L right shadow"
            ></div>
            <div
              style={{ width: 34, height: 39 }}
              className="shadow-right-M right shadow"
            ></div>
            <div
              style={{ width: 19.5, height: 21 }}
              className="shadow-right-S right shadow"
            ></div>
            <Image
              src="/assets/hero/play.png"
              alt="play"
              className="play"
              width={80}
              height={82}
            />
            <Image
              src="/assets/hero/music.png"
              alt="music"
              className="music"
              width={65}
              height={65}
            />
            <Image
              ref={ref}
              src="/assets/hero/chat.png"
              alt="chat"
              className="chat"
              width={84}
              height={86}
            />
          </div>

          <div
            className={cn("gradient-container", {
              animate: true, // isTextAnimated && firstScrollDown,
              "first-render": firstRender && !firstScrollDown,
            })}
            ref={elementRef}
          >
            <div
              className={cn("left-text", "gradient-text", {
                "firefox-browser": isFirefox,
              })}
            >
              Break your
            </div>
            <div
              className={cn("right-text", "gradient-text", {
                "firefox-browser": isFirefox,
              })}
            >
              website walls
            </div>
          </div>
          <h2 className="title-description">
            <p>
              Never settle, never sweat - just bring your dream website to life.
            </p>
            <p>
              Team up with Common Ninja and form an intelligent force of no code
              Widgets+ that gets down to business, helping you grow yours.
            </p>
          </h2>

          <CustomLink
            variant="gradient"
            href="/widgets"
            title="Explore Our Widgets"
          >
            Start creating
          </CustomLink>
          <SocialProof />
        </div>
      </StyledHero>
      <ParallaxEditor style={{ marginBottom: "80px" }} />
      <CommonNinjaWidgetRenderer
        widgetId={"87cf32fd-d8c2-4a3b-8cfc-0f419c000fbf"}
      />
    </>
  );
};
