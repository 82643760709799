"use client";

import styled from "styled-components";
import { Numbers } from "./numbers/numbers.comp";
import { Reviews } from "./reviews/reviews.comp";
import { CSSVars } from "@/styles/styles.config";

export const NumbersAndReviews = () => {
  return (
    <StyledNumbersFullContainer>
      <Numbers />
      <Reviews />
    </StyledNumbersFullContainer>
  );
};

export const StyledNumbersFullContainer = styled.div`
  padding: 100px 0px 50px;
  
  @media screen and (min-width: ${CSSVars.fontSizeMD}){
    padding: 0px 0px 50px;
  }

  background: radial-gradient(
      50% 53% at -20% 55%,
      rgba(76, 79, 255, 0.3) 8.5%,
      rgba(255, 255, 255, 0) 100%
    ),
    radial-gradient(
      50% 45% at 110% 40%,
      #ff979c 0%,
      rgba(255, 164, 169, 0.4) 8.5%,
      rgba(255, 255, 255, 0) 100%
    ),
    white;
`;
