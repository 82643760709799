"use client";

import { useRive, Layout, Fit } from "@rive-app/react-canvas";
import { CSSProperties, ReactNode, useEffect, useRef, useState } from "react";
import { useSpring, useTrail, animated } from "@react-spring/web";
import { StyledFeaturesSection } from "./features.styles";
import classNames from "classnames";
import { AnalyticsEditor } from "../analytics/analytics.comp";
import { TitlePropositions } from "../titlePropositions/titlePropositions.comp";
import Image from "next/image";

import feature1 from "../../../public/assets/feature1.png";
import feature2 from "../../../public/assets/feature2.png";
import feature3 from "../../../public/assets/feature3.png";
import { CustomLink } from "../link/link.comp";
import { IoIosArrowForward } from "@react-icons/all-files/io/IoIosArrowForward";

interface Feature {
  title: string;
  description: string;
  startTime: number;
  endTime: number;
  featureImage?: any;
}

const features: Feature[] = [
  {
    title: "Pick your widget+",
    description:
      "Select from our elite squad or create from scratch the no code Widget+ that will hit your website wish.",
    startTime: 0,
    endTime: 32,
    featureImage: feature1,
  },
  {
    title: "Customize your way",
    description:
      "Allow for a unique user experience with our Couple Clicks editor, optimized to build in an instant on any device.",
    startTime: 33,
    endTime: 66,
    featureImage: feature2,
  },
  {
    title: "Design to suit your vibe",
    description:
      "Enjoy customizing your widget+ with intuitive customization tools, tailoring to a tee, to match your brand style.",
    startTime: 67,
    endTime: 100,
    featureImage: feature3,
  },
];

export const Features = () => {
  const { rive, RiveComponent } = useRive({
    src: "https://website-assets.commoninja.com/assets/main_animation+(5).riv",
    animations: "Full Animation",
    layout: new Layout({
      fit: Fit.Contain,
    }),
    autoplay: false,
  });

  const { rive: rive2, RiveComponent: RiveComponent2 } = useRive({
    src: "https://website-assets.commoninja.com/assets/integrations.riv",
    artboard: "Desktop",
    animations: "Moving Integrations",
    layout: new Layout({
      fit: Fit.Contain,
    }),
    autoplay: false,
  });

  const { rive: rive3, RiveComponent: RiveComponent3 } = useRive({
    src: "https://website-assets.commoninja.com/assets/integrations.riv",
    artboard: "Mobile",
    animations: "Moving Integrations",
    layout: new Layout({
      fit: Fit.Contain,
    }),
    autoplay: false,
  });

  const { RiveComponent: RiveComponent4 } = useRive({
    src: "https://website-assets.commoninja.com/assets/integrations.riv",
    artboard: "Mobile",
    animations: "Moving Integrations",
    layout: new Layout({
      fit: Fit.Contain,
    }),
    autoplay: true,
  });

  const [activeIndex, setActiveIndex] = useState(0);
  const [containerRect, setContainerRect] = useState<{
    top: number;
    left: number;
  } | null>(null);
  const [boomSectionInView, setBoomSectionInView] = useState(false);
  const [boomSectionTransitionActive, setBoomSectionTransitionActive] =
    useState(false);
  const [boomSectionExpanded, setBoomSectionExpanded] = useState(false);
  const [moveAnimationToCenter, setMoveAnimationToCenter] = useState(false);
  const [removeFeatureText, setRemoveFeatureText] = useState(false);
  const [hideFeatureInfo, setHideFeatureInfo] = useState(false);

  const containerRef = useRef<HTMLDivElement | null>(null);
  const numbersTabRef = useRef<HTMLDivElement | null>(null);
  const boxRef = useRef<HTMLDivElement | null>(null);

  // Animations
  const boomSectionTrail = useTrail(3, {
    opacity: boomSectionInView && !boomSectionTransitionActive ? 1 : 0,
    transform: boomSectionInView ? "translateY(0)" : "translateY(20px)",
    from: { opacity: 0, transform: "translateY(20px)" },
    to: {
      opacity: boomSectionInView && !boomSectionTransitionActive ? 1 : 0,
      transform: boomSectionInView ? "translateY(0)" : "translateY(-20px)",
    },
    config: { tension: 170, friction: 26, mass: 1.5 },
    delay: boomSectionInView ? 300 : 0,
  });

  const boomSectionDescriptionSpring = useSpring({
    opacity: boomSectionInView && !boomSectionTransitionActive ? 1 : 0,
    transform: boomSectionInView ? "translateY(0)" : "translateY(-20px)",
    config: { tension: 200, friction: 20 },
    delay: boomSectionInView ? 1000 : 0,
  });

  const boomSectionTransitionTitleSpring = useSpring({
    opacity: boomSectionTransitionActive && !boomSectionExpanded ? 1 : 0,
    transform: boomSectionTransitionActive
      ? "translateY(0)"
      : "translateY(-20px)",
    config: { tension: 200, friction: 20 },
    delay: boomSectionTransitionActive ? 300 : 0,
  });

  const boomSectionTransitionDescriptionSpring = useSpring({
    opacity: boomSectionTransitionActive && !boomSectionExpanded ? 1 : 0,
    transform: boomSectionTransitionActive
      ? "translateY(0)"
      : "translateY(-20px)",
    config: { tension: 200, friction: 20 },
    delay: boomSectionTransitionActive ? 500 : 0,
  });

  function renderBoxContent() {
    if (boomSectionExpanded) {
      return (
        <div className="text-container">
          <AnalyticsEditor />
        </div>
      );
    } else if (boomSectionTransitionActive) {
      return (
        <div className="text-container">
          <>
            <animated.h3
              style={boomSectionTransitionTitleSpring}
              className="title"
            >
              Let your site, apps and data sources get talking
            </animated.h3>
            <animated.p
              className="description"
              style={boomSectionTransitionDescriptionSpring}
            >
              Integrate your Widgets+ with all popular platforms for a seamless
              experience across your entire digital stack. Your Widgets+ become
              intellegent tools with dynamic data, automation creations, and
              notifications about conversions.
              <CustomLink
                variant="underline"
                href="/features/integrations"
                className="integrations-button"
              >
                Learn More
                <IoIosArrowForward />
              </CustomLink>
            </animated.p>
          </>
        </div>
      );
    }

    return (
      <div className="text-container">
        <h3 className="text-overflow">
          {boomSectionTrail.map((style, idx) => {
            const words = ["Copy", "Paste", "Boom!"];
            return (
              <animated.span
                key={idx}
                style={style}
                className={words[idx].toLowerCase()}
              >
                {words[idx]}
              </animated.span>
            );
          })}
        </h3>
        <animated.p
          className="description"
          style={boomSectionDescriptionSpring}
        >
          {`It's`} live on your site
        </animated.p>
      </div>
    );
  }

  function renderMobileFeatureSection() {
    return (
      <>
        <section className={classNames(`feature-container-mobile`)}>
          {features.map((f, idx) => (
            <div key={f.title} className={classNames(`feature-card`)}>
              <Image src={f.featureImage} alt={f.title} />
              <div className={classNames(`feature-description`)} key={idx}>
                <span>{idx + 1}</span>
                <div className="wrapper">
                  <h2>{f.title}</h2>
                  <p>{f.description}</p>
                </div>
              </div>
            </div>
          ))}
          <div className="box-wrapper">
            <div className="box-container">
              <div className="text-container">
                <h3 className="text-overflow">
                  <span>Copy</span>
                  <span>Paste</span>
                  <span>Boom!</span>
                </h3>
                <p className="description">{`It's`} live on your site</p>
              </div>
            </div>
          </div>

          <div className={classNames(`integration-animation-wrapper`)}>
            <div className="mobile">
              <RiveComponent4 />
            </div>
          </div>

          <div className="integration-container">
            <h3 className="title">
              Let your site, apps and data sources get talking
            </h3>
            <p className="description">
              Integrate your Widgets+ with all popular platforms for a seamless
              experience across your entire digital stack. Your Widgets+ become
              intellegent tools with dynamic data, automation creations, and
              notifications about conversions.
            </p>
          </div>

          <div className="box-container expand">
            <div className="text-container">
              <AnalyticsEditor />
            </div>
          </div>
        </section>
      </>
    );
  }

  function renderFeatureSection() {
    return (
      <section
        className={classNames(`feature-container-desktop`, {
          expand: boomSectionExpanded,
        })}
        ref={containerRef}
        style={
          {
            "--left": `${containerRect?.left || 0}px`,
          } as CSSProperties
        }
      >
        <div
          className={classNames(`feature-card`, {
            center: !boomSectionExpanded,
          })}
        >
          {!removeFeatureText && (
            <div
              className={`number-tabs ${hideFeatureInfo ? "hide" : ""}`}
              ref={numbersTabRef}
            >
              {features.map((_, idx) => (
                <button
                  key={idx}
                  className={activeIndex === idx ? "active" : ""}
                >
                  <span>{idx + 1}</span>
                  {features[idx].title.split(" ")[0]}
                </button>
              ))}
            </div>
          )}
          <div
            className={classNames(`integration-animation-wrapper`, {
              show: boomSectionTransitionActive,
            })}
          >
            <div className="desktop">
              <RiveComponent2 />
            </div>
            <div className="mobile">
              <RiveComponent3 />
            </div>
          </div>

          <div
            ref={boxRef}
            className={classNames(`box-container`, {
              hide: !boomSectionInView,
              resize: boomSectionTransitionActive,
              expand: boomSectionExpanded,
            })}
          >
            {renderBoxContent()}
          </div>
          <div
            className={classNames(`animation-wrapper`, {
              hide: boomSectionInView,
              "move-to-center": moveAnimationToCenter,
            })}
          >
            <RiveComponent
              style={{
                maxWidth: "fit-content",
                borderRadius: "20px",
              }}
            />
          </div>
          {!removeFeatureText &&
            features.map((feature, idx) => {
              if (idx !== activeIndex) {
                return null;
              }

              return (
                <div
                  className={classNames(`feature-description`, {
                    hide: hideFeatureInfo,
                    active: idx === activeIndex,
                  })}
                  key={idx}
                >
                  <span>{idx + 1}</span>
                  <div className="wrapper">
                    <h2>{feature.title}</h2>
                    <p>{feature.description}</p>
                  </div>
                </div>
              );
            })}
        </div>
      </section>
    );
  }

  function renderCompleteControlSection() {
    return <section className="complete-control-section"></section>;
  }

  function updateContainerPosition() {
    if (containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      const stepsRect = numbersTabRef?.current?.getBoundingClientRect();
      const animationStartOffset = 400;
      const top = rect.top + window.scrollY - animationStartOffset; // Video's top relative to the document

      const gap = 60;
      const left = Math.round(rect.left + gap + (stepsRect?.width || 115));
      setContainerRect({ ...containerRect, top, left });
    }
  }

  function resetScrollAnimations() {
    setMoveAnimationToCenter(false);
    setBoomSectionInView(false);
    setRemoveFeatureText(false);
    setBoomSectionTransitionActive(false);
    setBoomSectionExpanded(false);
  }

  function lerp(start: number, end: number, factor: number) {
    return start + (end - start) * factor;
  }

  let previousScrollPercentage = 0;
  let animationFrameId: number;

  function handleScroll() {
    const animationStart =
      window.scrollY - (containerRect?.top || 0) <= 0
        ? 0
        : window.scrollY - (containerRect?.top || 0);
    const animationEnd = 5500;
    const targetScrollPercentage = Math.round(
      (animationStart / (animationEnd - window.innerHeight)) * 100
    );

    if (animationFrameId) {
      cancelAnimationFrame(animationFrameId);
    }

    const isFirefox =
      typeof navigator !== "undefined" &&
      navigator.userAgent.toLowerCase().includes("firefox");

    // Smoothly update the scroll percentage using `requestAnimationFrame`
    animationFrameId = requestAnimationFrame(() => {
      const smoothScrollPercentage = lerp(
        previousScrollPercentage,
        targetScrollPercentage,
        isFirefox && targetScrollPercentage >= 160 ? 1 : 0.2
      );

      // Update the previous scroll percentage for the next animation frame
      previousScrollPercentage = smoothScrollPercentage;

      if (boxRef.current) {
        const baseWidth = 500;
        const maxWidth = 650;
        const widthValue =
          smoothScrollPercentage >= 115
            ? baseWidth + (smoothScrollPercentage - 115) * 3
            : baseWidth;

        // Handle opacity fade between 115% and 120%
        let opacityValue = 0.5;

        if (smoothScrollPercentage >= 115 && smoothScrollPercentage <= 130) {
          opacityValue = (smoothScrollPercentage - 115) / 10;
          boxRef.current.style.transition = "0s";
        } else if (smoothScrollPercentage > 130) {
          opacityValue = 1;
          boxRef.current.style.transition = "0.3s";
        } else {
          opacityValue = 0;
          boxRef.current.style.transition = "0s";
        }

        boxRef.current.style.width = `${Math.min(widthValue, maxWidth)}px`;
        boxRef.current.style.height = `${Math.min(
          widthValue * 0.72,
          maxWidth * 0.72
        )}px`;
        boxRef.current.style.opacity = `${opacityValue}`;
      }

      if (smoothScrollPercentage >= 95) {
        resetScrollAnimations();
        setHideFeatureInfo(true);

        // Move video to the center before it disappears
        if (smoothScrollPercentage >= 100) {
          setMoveAnimationToCenter(true);
        }

        if (smoothScrollPercentage >= 115) {
          setBoomSectionInView(true);
          setRemoveFeatureText(true);
        }

        if (smoothScrollPercentage >= 160) {
          setBoomSectionTransitionActive(true);

          if (rive2) {
            const divider = 6.5;
            const scrubPosition = (smoothScrollPercentage - 160) / divider;
            rive2.scrub("Moving Integrations", scrubPosition);
          }

          if (rive3) {
            const divider = 3;
            const scrubPosition = (smoothScrollPercentage - 160) / divider;
            rive3.scrub("Moving Integrations", scrubPosition);
          }

          if (boxRef.current) {
            const expandBaseWidth = 650;
            const expandMaxWidth = 1000;
            const expandWidthValue =
              expandBaseWidth + (smoothScrollPercentage - 160) * 3;

            boxRef.current.style.width = `${Math.min(
              expandWidthValue,
              expandMaxWidth
            )}px`;
            boxRef.current.style.height = `${Math.min(
              expandWidthValue * 0.72,
              expandMaxWidth * 0.72
            )}px`;
          }
        }

        if (smoothScrollPercentage >= 200) {
          setBoomSectionExpanded(true);
          if (boxRef.current) {
            boxRef.current.style.width = `100%`;
            boxRef.current.style.height = `900px`;
          }
        }
      } else {
        resetScrollAnimations();
        setHideFeatureInfo(false);
      }

      if (rive && smoothScrollPercentage <= 100) {
        // Control the animation's timeline with scrub

        const divider = 11; // divider (number to divide by for getting the total seconds of the animation, in this case 9s)
        const scrubPosition = smoothScrollPercentage / divider;
        rive.scrub("Full Animation", scrubPosition);

        // Set the active feature based on scrubbed animation time
        const activeFeatureIndex = features.findIndex(
          (feature) =>
            scrubPosition * divider >= feature.startTime &&
            scrubPosition * divider < feature.endTime
        );
        if (activeFeatureIndex !== -1) {
          setActiveIndex(activeFeatureIndex);
        }
      }

      // Call the function again for smooth updates
      if (Math.abs(targetScrollPercentage - smoothScrollPercentage) > 0.1) {
        animationFrameId = requestAnimationFrame(handleScroll);
      }
    });
  }

  useEffect(() => {
    window.addEventListener("scroll", updateContainerPosition);
    window.addEventListener("resize", updateContainerPosition);
    updateContainerPosition(); // Initial position update

    return () => {
      window.removeEventListener("scroll", updateContainerPosition);
      window.removeEventListener("resize", updateContainerPosition);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Initial position update

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rive, containerRect?.top]);

  return (
    <StyledFeaturesSection>
      <TitlePropositions />
      {renderFeatureSection()}
      {renderMobileFeatureSection()}
      {renderCompleteControlSection()}
    </StyledFeaturesSection>
  );
};
