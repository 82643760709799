import styled from "styled-components";
import { CSSVars } from "../../styles/styles.config";

export const StyleTitleProposition = styled.div`
  padding: 60vh 9%;
  background: radial-gradient(
      40% 50% at -17% 58%,
      #ff979c 0%,
      rgba(255, 151, 156, 0.64) 34%,
      rgba(255, 151, 156, 0) 100%
    ),
    radial-gradient(30% 35% at 100% 45%, #66f -40%, rgba(0, 0, 0, 0) 110%);

  h1 {
    max-width: 960px;
    margin: auto;
    color: white;
    text-align: center;
    font-size: ${CSSVars.fontSize5XL};
    font-weight: 300;
    line-height: 1.2;
    width: 100%;
    white-space: pre-wrap;
  }

  @media screen and (max-width: ${CSSVars.breakpointLG}) {
    padding: 400px 20px;

    h1 {
      font-size: ${CSSVars.fontSize2XL};
    }
  }

  @media screen and (max-width: ${CSSVars.breakpointSM}) {
    padding: 200px 20px;
  }
`;
